.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(8px + 1.8vmin);
  color: white;
}

.app-name {
  color: #ec415a;
  position: absolute;
  top: 1vh;
}

.App-link {
  color: #61dafb;
}

.inline {
  display: inline;
}

.inline-block {
  display: inline-block;
}

.search-progress {
  vertical-align: middle;
  margin-left: 25px;
}

.search-progress-color {
  color: #000;
}

.MuiCircularProgress-colorSecondary{
  color : "#000";
}

.home_root {
  margin-top: 18vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.home_container {
  display: flex;
  background: #ffffff;
  min-height: 82vh;
  width: 55vw;
  max-width: 100%;
}

.heading {
  font-size: 2rem;
}

.initial {
  margin: 90px;
}

.searchBar {
  position: absolute;
  top: 5vh;
  left: 2.5%;
  width: 35%;
  text-align: start;
}

.search {
  width: 70%;
}

.main {
  max-width: 95vw;
  margin-top: 15vh;
}

.table_container {
  max-height: 75vh;
}


#instructions {
  display: flex;
  flex-direction: column;
  color: #333;
  font-size: 1.6rem;
  padding-top: 40vh;
  padding-left: 25%;
  padding-right: 10%;
  text-align: start;
  width: 60%;
}

#instructions ol{
  padding: 0 0 0 32px;
}


#logout {
  position: absolute;
  top: 4vh;
  right: 10%;
}


#qr {
  position: absolute;
  top: 25vh;
  left: 50%; transform: translate(-50%,0);
}

#qr_reload {
  position: absolute;
  top: 24.8vh;
  left: 50%; transform: translate(-50%,0);
}

._2ezGC{
  display:inline-block;
  vertical-align:top;
}



@media only screen and (min-width: 768px) and (max-width: 1250px) {

  .App-header {
    font-size: 1.6rem;
  }

  .home_container {
    width: 75%;
  }

  .heading {
    font-size: 1.75rem;
  }

  .initial {
    margin: 60px;
  }

  #qr_reload_img {
    height: 224px;
    width: 224px;
  }

  #instructions {
    font-size: 1.4rem;
    /* width: 100%; */
  }

  #instructions ol{
    padding: 0 0 0 28px;
  }

} 

/* For Small Mobile phones
*/
@media only screen and (max-width: 768px) {
  .App-header {
    font-size: 1.4rem;
  }

  .home_container {
    width: 95%;
  }

  .heading {
    font-size: 1.5rem;
  }

  .searchBar {
    top: 12vh;
    left: 25%;
    width: 70%;
  }

  .initial {
    margin: 30px;
  }

  #qr_reload_img {
    height: 192px;
    width: 192px;
  }

  #instructions {
    font-size: 1.25rem;
    padding-right: 25%;
    width: 75%;
  }

  #instructions ol{
    padding: 0 0 0 25px;
  }


} 


@media only screen and (max-width: 500px) {

  .table_container {
    max-height: 65vh;
  }

  .searchBar {
    left: 15%;
    width: 90%;
  }

  #logout {
    top: 2.25vh;
    right: 0;
  } 

  .app-name {
    font-size: 1.6rem;
  }



}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
